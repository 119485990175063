import {
    INITCONTRACT
} from "./constants";


const defaultState = {
    base_img: "",
    sign_signature:"",
    id:0,
    orderid:0,
    contract_id:0,
    pay_type:0,
    sign_information_value:{
        
    }
}


export default (state = defaultState, action) => {
    switch (action.type) {
        case INITCONTRACT:
            return {
                ...state,
                ...action.data
            }
        default:
            return state;

    }
}