const isBebug = process.env.DEBUG;


const Url = process.env.REACT_APP_SECRET_API;
const Env = process.env.REACT_APP_SECRET_ENV;

const config = {
    /**
     * 开发模式
     */
    //https://testapi.galaxy-immi.com
    development: {
        baseUrl: Url,
        pageSize: 10,
        Debug: true,
        BaiduSrc: "https://hm.baidu.com/hm.js?7414086ae69fbe98691de35558bae385"
    },
    test: {
        baseUrl: Url,
        pageSize: 10,
        Debug: true,
        BaiduSrc: "https://hm.baidu.com/hm.js?7414086ae69fbe98691de35558bae385"
    },
    release: {
        baseUrl: Url,
        pageSize: 10,
        Debug: true,
        BaiduSrc: "https://hm.baidu.com/hm.js?7414086ae69fbe98691de35558bae385"
    },
    /**
     * 生产模式
     */
    production: {
        baseUrl: Url,
        pageSize: 10,
        Debug: false,
        BaiduSrc: "https://hm.baidu.com/hm.js?c1583ae3b0780dcce7494cc115147de0"
    }
}
//export default config['Env'];
//console.log(config[`${Env}`])
export default config[`${Env}`];
//export default config[`${Env}`];