import {
    combineReducers
} from "redux";
import {
    reducer as HomeReducer
} from "../pages/Home/store"
import {
    reducer as ConcordReducer
} from "../pages/Concord/store"
export default combineReducers({
    HomeReducer,
    ConcordReducer
})