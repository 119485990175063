import {
   
    INITCONTRACT
} from "./constants";




export  const  initContract =  (data) =>{
    return {
        type: INITCONTRACT,
        data:data
    }
}

