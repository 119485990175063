import Cookies from 'js-cookie'
class storage {

    static delCookie(name) {
        //  如果是非本地环境，增加cookie domain
        if(process.env.REACT_APP_SECRET_ENV === "development"){
            Cookies.remove('token')
        } else {
            Cookies.remove("token", { domain: '.galaxy-immi.com' });
        }
    }

    static getCookie(v) {
        return  Cookies.get(v)
    }

    static setCookie(name, value) {
        //  如果是非本地环境，增加cookie domain
        if(process.env.REACT_APP_SECRET_ENV === "development"){
            Cookies.set(name,value)
        } else {
            Cookies.set(name, value, { domain: '.galaxy-immi.com' });
        }
    }

    static getToken() {
        return  Cookies.get('token')
    }

    static setToken(token) {
        this.setCookie('token', token)
    }

}

export default storage;