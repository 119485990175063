import {
    ADD_COUNT, REMOVE_COUNT
} from "./constants";


const defaultState = {
    count: 1
}


export default (state = defaultState, action) => {
    switch (action.type) {
        case ADD_COUNT:
            return {
                ...state,
                count: ++state.count
            }
        case REMOVE_COUNT:
            return {
                ...state,
                count: --state.count
            }
        default:
            return state;

    }
}