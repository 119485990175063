import {injectGlobal} from 'styled-components'

injectGlobal`
html{font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%} 
html,body{-webkit-user-select:none;user-select:none;} 
html,body,div,object,iframe,applet,object,h1,h2,h3,h4,h5,h6,p,blockquote,pre,address,dl,dt,dd,ol,ul,li,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,menu,nav,output,ruby,section,summary,time,mark,audio,video,progress{margin:0;padding:0;border:0;vertical-align:baseline} 
a{text-decoration:none;-webkit-touch-callout:none;background-color:transparent} 
li{list-style:none} 
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section,summary{display:block} 
audio,canvas,progress,video{display:inline-block} 
audio:not([controls]){display:none;height:0} 
[hidden],template{display:none} 
a:active,a:hover{outline:0} 
abbr[title]{border-bottom:1px dotted} 
b,strong{font-weight:bold} 
dfn{font-style:italic} 
h1{font-size:2em;margin:.67em 0} 
small{font-size:80%} 
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline} 
sup{top:-0.5em} 
sub{bottom:-0.25em} 
img{border:0;-webkit-touch-callout:none;} 
svg:not(:root){overflow:hidden} 
figure{margin:1em 40px} 
hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0} 
pre{overflow:auto} 
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em} 
a,button,input,optgroup,select,textarea{-webkit-tap-highlight-color:rgba(0,0,0,0);} 
button,input,optgroup,select,textarea{color:inherit;font:inherit;margin:0;-webkit-appearance:none;outline:none;line-height:normal} 
button{overflow:visible} 
button,select{text-transform:none} 
button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer} 
button[disabled],html input[disabled]{cursor:default} 
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0} 
input{line-height:normal} 
input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0} 
input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button{height:auto} 
input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box} 
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none} 
fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em} 
legend{border:0;padding:0} 
textarea{overflow:auto} 
optgroup{font-weight:bold} 
table{border-collapse:collapse;border-spacing:0} 
td,th{padding:0} 

*{
    padding:0;
    maring:0;
}
body{
    background:#fff;
}
@media (min-width:240px) and (max-width:320px){html{font-size:12.8px!important}}
@media (min-width:321px) and (max-width:360px){html{font-size:14.4px!important}}
@media (min-width:361px) and (max-width:375px){html{font-size:15px!important}}
@media (min-width:376px) and (max-width:384px){html{font-size:15.36px!important}}
@media (min-width:385px) and (max-width:414px){html{font-size:16.56px!important}}
@media (min-width:415px) and (max-width:450px){html{font-size:18px!important}}
@media (min-width:451px) and (max-width:500px){html{font-size:20px!important}}
@media (min-width:501px) and (max-width:550px){html{font-size:22px!important}}
@media (min-width:551px) and (max-width:569px){html{font-size:22.72px!important}}
@media (min-width:569px) and (max-width:640px){html{font-size:25.6px!important}}
@media (min-width:641px) and (max-width:667px){html{font-size:26.68px!important}}
@media (min-width:668px) and (max-width:736px){html{font-size:29.44px!important}}
@media (min-width:738px) and (max-width:768px){html{font-size:30.72px!important}}
@media (min-width:769px) and (max-width:900px){html{font-size:36px!important}}
@media (min-width:901px) and (max-width:1080px){html{font-size:43.24px!important}}
@media screen and (orientation: portrait) {
  
    body {
        color: green;
    }
    .main {
        -webkit-transform:rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
       
        /*去掉overflow 微信显示正常，但是浏览器有问题，竖屏时强制横屏缩小*/
        overflow: hidden;
    }
    
}
@media screen and (orientation: landscape) {
   
    .main {
        -webkit-transform:rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0)
    }
}
.am-toast.am-toast-mask
{
    z-index:999999999999999999;
}

.am-list-item.am-input-disabled .am-input-label {
    color: #000!important;
}
.am-textarea-disabled .am-textarea-label {
    color: #000!important;
}

.am-list-item .am-input-control input:disabled {
    color: #999!important;
    background-color: #fff;
}
.am-textarea-control textarea:disabled {
    color: #999!important;
    background-color: #fff;
}
.am-modal-transparent {
    width: 300px!important;
}
.am-icon-md {
    width: 28px!important;
    height: 28px!important;
   
}
.fixedNav{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

.am-list-header{
    background:#fff;
}

.am-modal-wrap
{
    z-index:9999999999999999999999!important;
}

.am-list-item .am-input-label.am-input-label-5 {
    width: 105px!important;
}

.am-textarea-label.am-textarea-label-5 {
    width: 105px!important;
}


`


