
import asyncComponent from './../until/asyncComponent';
const Home=asyncComponent(()=>import(/* webpackChunkName: "Home" */"../pages/Home"))   //异步加载，js 单独一个文件
const Login=asyncComponent(()=>import(/* webpackChunkName: "Login" */"../pages/Login"))  
const LoginHongKong=asyncComponent(()=>import(/* webpackChunkName: "Login" */"../pages/LoginHongKong"))  
const Concord=asyncComponent(()=>import(/* webpackChunkName: "Concord" */"../pages/Concord"))  
const Signed=asyncComponent(()=>import(/* webpackChunkName: "Signed" */"../pages/Signed"))  
const NotFound=asyncComponent(()=>import(/* webpackChunkName: "NotFound" */"../pages/NotFound"))  
const Signature=asyncComponent(()=>import(/* webpackChunkName: "Signature" */"../pages/Signature"))  
const WxSignature=asyncComponent(()=>import(/* webpackChunkName: "WxSignature" */"../pages/WxSignature"))  
const PreviewContract=asyncComponent(()=>import(/* webpackChunkName: "PreviewContract" */"../pages/PreviewContract"))   
const PreviewH5=asyncComponent(()=>import(/* webpackChunkName: "PreviewH5" */"../pages//PreviewH5"))   
const routes = [
    {
        path: '/',
        exact: true,
        component: Home,
        requiresAuth: true,
    },
    {
        path: '/login',
        component: Login,
        requiresAuth: false,

    },
    {
        path: '/loginhongkong',
        component: LoginHongKong,
        requiresAuth: false,

    },
    {
        path: '/concord/:id',
        component: Concord,
        requiresAuth: true,

    },
    {
        path: '/signed/:id',
        component: Signed,
        requiresAuth: true,

    },
    {
        path: '/signature/:id/:orderid',
        component: Signature,
        requiresAuth: true,

    },
    {
        path: '/wxsignature/:id/:orderid',
        component: WxSignature,
        requiresAuth: true,

    },
    {
        path: '/previewcontract/:id',
        component: PreviewContract,
        requiresAuth: true,
    },
    {
        path: '/previewh5/:id',
        component: PreviewH5,
        requiresAuth: true,
    },
    {
        path: '*',
        component: NotFound,
        requiresAuth: false,
    }
]


export default routes;


/**
 * 
 * 
 * 
 * var data = {id:3,name:sam,age:36};
var path = {
  pathname:'/user',
  query:data,
}
1.hashHistory.push(path);
2
3  获取参数var data = this.props.location.query;
            var {id,name,age} = data;
4
5
1.var path = `/user/${data}`;
1
2
3
1.<Link to={path}>用户</Link>
hashHistory.push(path);

var data = JSON.parse(this.props.params.data);
var {id,name,age} = data;
 */